import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../../services/booking.service';
import { NationalityService } from '../../services/nationality.service';
import { FlightScheduleService } from '../../services/flight-schedule.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  seats: any;
  type: any;
  fromSchedule: any;
  toSchedule: any;
  totalAmounth: any;
  arrayForms: any = [];
  selectedArray = new Object();
  selectedArrayPassengers: any = [];
  sendFormValidate = false;
  nationalityList: any;
  availableSeats: any;
  carryOnBagageFrom: any;
  checkedBagageFrom: any;
  carryOnBagageTo: any;
  checkedBagageTo: any;
  fromDate: any;
  toDate: any;
  locationCombination: any;
  governmentContractorFlight = false;

  constructor(
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _bookingService: BookingService,
    private readonly _nationalityService: NationalityService,
    private readonly _flightScheduleService: FlightScheduleService
  ) { }

  async ngOnInit() {
    this.seats = this._route.snapshot.params.seats;
    this.type = this._route.snapshot.params.type;
    this.fromSchedule = this._route.snapshot.params.fromSchedule;
    this.toSchedule = (this._route.snapshot.params.toSchedule != 'null') ? this._route.snapshot.params.toSchedule: false;
    this.totalAmounth = this._route.snapshot.params.totalAmounth;
    this.fromDate = this._route.snapshot.params.fromDate;
    this.toDate = this._route.snapshot.params.toDate;
    this.locationCombination = this._route.snapshot.params.locationCombination;
    this.carryOnBagageFrom = this._route.snapshot.params.carryOnBagageFrom;
    this.checkedBagageFrom = this._route.snapshot.params.checkedBagageFrom;
    this.carryOnBagageTo = this._route.snapshot.params.carryOnBagageTo;
    this.checkedBagageTo = this._route.snapshot.params.checkedBagageTo;
    this.nationalityList = await this._nationalityService.getList();
    const temp: any = await this._flightScheduleService.getAvailableSeatsBySchedule(this.fromSchedule);
    this.availableSeats = temp.seats;
    const isGovernment: any = await this._flightScheduleService.isGovernment(this.fromSchedule);
    let isGovernmentReturn: any = false;
    if (this.toSchedule) {
      isGovernmentReturn = await this._flightScheduleService.isGovernment(this.toSchedule);
    }
    if (isGovernment.governmentContractorFlight || isGovernmentReturn.governmentContractorFlight) {
      this.governmentContractorFlight = true;
    }
    this.generateforms()
  }

  generateforms() {
    if (parseInt(this.seats) > 0) {
      for (let i = 1; i <= parseInt(this.seats);i++) {
        let newObj = new Object();
        newObj['form'+i] = new FormGroup({
          seat: new FormControl(''),
          firstName: new FormControl('', Validators.required),
          lastName: new FormControl('', Validators.required),
          dateOfBirth: new FormControl('', Validators.required),
          mobile: new FormControl('', Validators.required),
          passport: new FormControl('', Validators.required),
          isComplete: new FormControl(false),
          email: new FormControl('', [Validators.required, Validators.email]),
          other: new FormControl(),
          nationalityId: new FormControl(),
          emergencyName: new FormControl(),
          emergencyPhone: new FormControl(),
          nameOfEmployer: new FormControl(null, (this.governmentContractorFlight) ? Validators.required: null),
          POCPhone: new FormControl(null, (this.governmentContractorFlight) ? Validators.required: null),
          POCEmail: new FormControl(null, (this.governmentContractorFlight) ? [Validators.required, Validators.email]: null),
          POCName: new FormControl(null, (this.governmentContractorFlight) ? Validators.required: null)
        })
        this.arrayForms.push(newObj);
        this.selectedArray['form'+i] = false;
      }
    }
  }

  validateAllRight() {
    let validate = false;
    for(const i in this.selectedArray) {
      if (!this.selectedArray[i]) {
        validate = true;
      }
    }
    if (!validate) {
      this.sendFormValidate = true;
    }
  }

  saveForm(formName:string) {
    Swal.fire({
      title: 'Are your sure you want to make these changes?',
      text: 'These changes cannot be reversed',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#27509A',
      cancelButtonColor: '#C94F1B',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.selectedArray[formName] = true;
        let temporal: any = [];
        for (const i in this.arrayForms) {
          if (this.arrayForms[i][formName]) {
            temporal = this.arrayForms[i][formName].value
            // this.arrayForms[i][formName].disable();
          }
        }
        this.selectedArrayPassengers.push(temporal);
        this.validateAllRight();
      }
    });
  }

  saveTheForm() {
    Swal.fire({
      title: 'Are you sure to create a new booking?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#27509A',
      cancelButtonColor: '#C94F1B',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        let newObj: any = new Object();
        newObj.departureSchedule = this.fromSchedule;
        if (this.toSchedule) {
          newObj.returnSchedule = this.toSchedule;
        }
        newObj.type = this.type;
        newObj.totalAmounth = parseFloat(this.totalAmounth);
        newObj.passengers = this.selectedArrayPassengers;
        newObj.requiredPayment = true;
        let newArray = [];
        for (const i of this.selectedArrayPassengers) {
          const newObj: any = new Object();
          newObj.code = i.seat;
          newArray.push(newObj);
        }
        newObj.seats = newArray;
        newObj.paymentType = 'Admin web';
        newObj.fromDate = this.fromDate;
        newObj.toDate = this.toDate;
        newObj.locationCombination = this.locationCombination;
        newObj.carryOnBagageFrom = this.carryOnBagageFrom;
        newObj.checkedBagageFrom = this.checkedBagageFrom;
        newObj.carryOnBagageTo = this.carryOnBagageTo;
        newObj.checkedBagageTo = this.checkedBagageTo;
        //const newString = JSON.stringify(newObj);
        // localStorage.setItem('BOOKYNGSYSTEM::paymentInfo', newString);
        // this._router.navigate([`/booking-ticket/payment`]);
        this._bookingService.create(newObj).then((response)=>{
          // this._router.navigate([`/booking-ticket`]);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        });
      }
    });
  }
}
