import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationCombinationComponent } from './location-combination.component';
import { APP_ROUTES_MAIN } from './location-combination.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../../shared/shared.module';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    LocationCombinationComponent,
    CreateComponent,
    ListComponent
  ],
  imports: [
    APP_ROUTES_MAIN,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DataTablesModule
  ]
})
export class LocationCombinationModule { }
