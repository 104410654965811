import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FlightScheduleService {

  url: string = 'flight-schedule';

  constructor(private http: HttpClient) { }

  getList() {
    return this.http.get(`${environment.urlApi}/${this.url}`).toPromise();
  }

  getScheduleWithPassenger(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}/find-schedule`, body).toPromise();
  }

  checkin(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}/checkin`, body).toPromise();
  }

  search(search: any) {
    return this.http.post(`${environment.urlApi}/${this.url}/search`, search).toPromise();
  }

  getOne(id: string) {
    return this.http.get(`${environment.urlApi}/${this.url}/${id}`).toPromise();
  }

  getAvailableSeats() {
    return this.http.get(`${environment.urlApi}/${this.url}/available`).toPromise();
  }

  isGovernment(id:string) {
    return this.http.get(`${environment.urlApi}/${this.url}/government/${id}`).toPromise();
  }

  isGovernmentSchedule(id:string) {
    return this.http.get(`${environment.urlApi}/${this.url}/government-schedule/${id}`).toPromise();
  }

  getAvailableSeatsBySchedule(id:string) {
    return this.http.get(`${environment.urlApi}/${this.url}/available/${id}`).toPromise();
  }

  getPassengersBySchedule(id:string) {
    return this.http.get(`${environment.urlApi}/${this.url}/passengers/${id}`).toPromise();
  }

  getPassengersByCodeSchedule(code: string) {
    return this.http.get(`${environment.urlApi}/${this.url}/passengers-checkin/${code}`).toPromise();
  }

  create(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}`, body).toPromise();
  }

  modify(body: any) {
    return this.http.put(`${environment.urlApi}/${this.url}`, body).toPromise();
  }

}
