import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlightScheduleService } from '../../services/flight-schedule.service';
import { LocationCombinationService } from '../../services/location-combination.service';
import { AirplaneService } from '../../services/airplane.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns'

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  _id: string;
  elements: any = [];
  modify: boolean = false;
  otherList: any = [];
  otherList2: any = [];
  airplane: any;
  availableBS: number = 0;
  availableES: number = 0;

  elemForm = new FormGroup({
    locationCombinationId: new FormControl('', Validators.required),
    airplaneId: new FormControl('', Validators.required),
    carryOnBaggage: new FormControl('', Validators.required),
    checkedBaggage: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    durationAprox: new FormControl('', Validators.required),
    priceEconomy: new FormControl(0, [Validators.required, Validators.min(0)]),
    priceBusiness: new FormControl(0, [Validators.required, Validators.min(0)]),
    isActive: new FormControl(true),
    governmentContractorFlight: new FormControl(false, Validators.required),
    taxes: new FormControl(0, Validators.required)
  })

  constructor(private readonly _service: FlightScheduleService, private readonly _service2: LocationCombinationService, private readonly _service3: AirplaneService, private readonly _router: Router, private _route: ActivatedRoute) { }

  async ngOnInit() {
    this._id = this._route.snapshot.paramMap.get('id');
    this.otherList = await this._service2.getList();
    this.otherList2 = await this._service3.getList();
    if(this._id) {
      this.modify = true;
      const doc: any = await this._service.getOne(this._id);
      this.availableBS = doc.availableSeatsBusiness;
      this.availableES = doc.availableSeatsEconomy;
      doc.date = format(new Date(doc.date), "yyyy-MM-dd'T'HH:mm");
      this.elemForm.patchValue(doc);
    }
  }

  async send() {
    const form = this.elemForm.value;
    form.date = new Date(form.date).toISOString();
    if(this._id && this.modify) {
      Swal.fire({
        title: 'Are your sure you want to make these changes?',
        text: 'These changes cannot be reversed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Modify',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          form._id = this._id;
          this._service.modify(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/flight-schedule`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure to create a new flight schedule?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          this._service.create(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/flight-schedule`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    }
  }

  changeAirplane() {
    const {airplaneId} = this.elemForm.value;
    for (const i of this.otherList2) {
      if (airplaneId == i._id) {
        this.airplane = i;
      }
    }
  }

  resetAll() {
    this._id = null;
    this.modify = false;
    this.elemForm.reset();
  }

}
