import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { BookingService } from '../../services/booking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})

export class PaymentComponent implements OnInit {

  cliendId: any;
  passengers: any;
  departure: any;
  date: any;
  totalAmounth: any;
  carryOnBagage: any;
  checkedBagage: any;
  objToSend: any;
  public payPalConfig?: IPayPalConfig;
  constructor(private readonly _bookingService: BookingService, private readonly _router: Router) {
    this.cliendId = environment.paypalcliend;
  }

  async ngOnInit() {
    const paymentdocument = JSON.parse(localStorage.getItem('BOOKYNGSYSTEM::paymentInfo'));
    this.objToSend = paymentdocument;
    this.passengers = paymentdocument.passengers;
    this.departure = paymentdocument.locationCombination;
    this.date = paymentdocument.fromDate;
    this.totalAmounth = paymentdocument.totalAmounth;
    this.carryOnBagage = paymentdocument.carryOnBagageFrom;
    this.checkedBagage = paymentdocument.checkedBagageFrom;
    this.payPalConfig = {
      currency: 'USD',
      clientId: this.cliendId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.totalAmounth,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.totalAmounth,
                  },
                },
              },
              items: [
                {
                  name: 'Frontera SKY Booking' ,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: this.totalAmounth,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
          if (details.status === "APPROVED") {
            this.objToSend.paymentReference = details.id;
            this._bookingService.create(this.objToSend).then((response)=>{
              this._router.navigate([`/booking-ticket`]);
              Swal.fire('Success', 'Your payment has been success', 'success');
            });
          }
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
        //this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        //this.showCancel = true;
      },
      onError: (err) => {
        console.log('OnError', err);
        //Swal.fire();
        //this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        //this.resetStatus();
      },
    };
  }

}
