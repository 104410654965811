import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { BookingService } from './../services/booking.service';
import { subDays, format } from 'date-fns';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  newBooking: any = '';
  seatsVacant: any = '';
  income: any = '';
  cancellation: any = '';
  lineChartData: ChartDataSets[] = [
    { data: [], label: 'Sales' }
  ];

  lineChartLabels: Label[] = this.fillDates();

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#d57c1b',
      backgroundColor: 'rgba(213, 123, 27, 0.342)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  constructor(private readonly _bookingService: BookingService) { }

  async ngOnInit() {
    const doc:any = await this._bookingService.getDashboard();
    console.log(doc);
    this.newBooking = doc.newBooking;
    this.seatsVacant = doc.seatsAreVacant;
    this.income = this.sumTotal(doc.income);
    this.cancellation = doc.cancellation;
    this.lineChartData[0]['data'] = doc.chart;
  }

  sumTotal (arrayDoc: any) {
    let sum = 0;
    for (const i of arrayDoc) {
      sum+=i.count;
    }
    return sum
  }

  fillDates() {
    let array = [];
    for(var i = 6; i>= 0; i--) {
      array.push(format(subDays(new Date, i), 'dd/MM/yyyy'));
    }
    return array;
  }

}
