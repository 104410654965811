import { Routes, RouterModule } from '@angular/router';
import { CheckSessionGuard } from '../../shared/services/guards/session.guards';
import { CheckUserTypeGuard } from './../../shared/services/guards/user-type.guard';
import { NationalityComponent } from './nationality.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';

const appRoutes: Routes = [
  {
    path: 'nationality',
    component: NationalityComponent,
    children: [
      {
        path: 'list',
        component: ListComponent,
        canActivate: [ CheckSessionGuard, CheckUserTypeGuard ]
      },
      {
        path: 'create',
        component: CreateComponent,
        canActivate: [ CheckSessionGuard, CheckUserTypeGuard ]
      },
      {
        path: 'modify/:id',
        component: CreateComponent,
        canActivate: [ CheckSessionGuard,CheckUserTypeGuard ]
      },
      {
        path: '',
        redirectTo: '/nationality/list',
        pathMatch: 'full'
    },
    ]
  }
];

export const APP_ROUTES_MAIN = RouterModule.forChild(appRoutes);
