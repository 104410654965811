import { Routes, RouterModule } from '@angular/router';
import { CheckSessionGuard } from '../../shared/services/guards/session.guards';
import { BookingTicketComponent } from './booking-ticket.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { PaymentComponent } from './payment/payment.component';

const appRoutes: Routes = [
  {
    path: 'booking-ticket',
    component: BookingTicketComponent,
    children: [
      {
        path: 'list',
        component: ListComponent,
        canActivate: [ CheckSessionGuard ]
      },
      {
        path: 'create',
        component: CreateComponent,
        canActivate: [ CheckSessionGuard ]
      },
      {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [ CheckSessionGuard ]
      },
      {
        path: '',
        redirectTo: '/booking-ticket/list',
        pathMatch: 'full'
    },
    ]
  }
];

export const APP_ROUTES_MAIN = RouterModule.forChild(appRoutes);
