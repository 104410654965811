import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavBarTransaction } from './nav-bar-transaction/nav-bar-transaction.component';
import { NavBarReportComponent } from './nav-bar-report/nav-bar-report.component';

@NgModule({
  declarations: [
    NavBarComponent,
    NavBarTransaction,
    NavBarReportComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
  ],
  providers: [],
  exports: [
    NavBarComponent,
    NavBarTransaction,
    NavBarReportComponent
  ]
})
export class SharedModule { }
