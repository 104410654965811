// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // urlApi: 'https://apistg.fronterasky.com/',
  // urlApi: 'https://api.fronterasky.com', //producción
  // urlApi:'https://frontera-api.herokuapp.com',
  urlApi: 'https://api-frontera-app.herokuapp.com',
  // paypalcliend: 'AUKNUu9bkoVkwtzU0Q11ciioGE8tEai17AXMtHwgpYkc4jG5Yn9c6cb3fhxsxjR6Kgpln8kG3h4DX4-V' // producción
  paypalcliend: 'AeFvD33GTTZDbH6TCSb9_zh_E6g9L6wChkU1iF9KUhiNCf-UT9wq5JZpPsaPfGZ91T6pjaqA4jQFyWfa'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
