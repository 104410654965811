import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  _id: any;
  document: any;
  passengers: any = [];
  elemForm = new FormGroup({
    isCancelled: new FormControl(false),
    cancellationReason: new FormControl('')
  })
  constructor(
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _bookingService: BookingService,
  ) { }

  async ngOnInit() {
    this._id = this._route.snapshot.paramMap.get('id');
    const doc: any = await this._bookingService.getOneByCode(this._id);
    this.document = doc;
    this.passengers = doc.passengers;
    this.elemForm.patchValue({
      isCancelled: doc.isCancelled,
      cancellationReason: doc.cancellationReason
    });
    console.log(doc);
    console.log(this.passengers);
  }

  async send() {
    const body = this.elemForm.value;
    const {isCancelled, cancellationReason} = body;
    Swal.fire({
      title: 'Are your sure you want to make these changes?',
      text: 'These changes cannot be reversed',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#27509A',
      cancelButtonColor: '#C94F1B',
      confirmButtonText: 'Modify',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.document.isCancelled = isCancelled;
        this.document.cancellationReason = cancellationReason;
        this._bookingService.modify(this.document).then((response)=>{
          this._router.navigate([`/cancel-booking`]);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        });
      }
    })
  }

}
