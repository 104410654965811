import { Component, OnInit } from '@angular/core';
import { PassengerService } from '../services/passenger.service';

@Component({
  selector: 'app-passenger',
  templateUrl: './passenger.component.html',
  styleUrls: ['./passenger.component.sass']
})
export class PassengerComponent implements OnInit {

  _id: string;
  elements: any = [];
  modify: boolean = false;


  constructor(private readonly _service: PassengerService) { }

  async ngOnInit() {
    //await this.loadList();
  }

}
