import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  constructor(private readonly _userService: AuthService,private readonly _router: Router) { }

  ngOnInit() {

  }

  send = async ():Promise<void> => {
    const form = this.loginForm.value;
    this._userService.login(form).subscribe(() => {
      Swal.fire({
        titleText: 'Welcome',
        text: '',
        icon:'success'
      });
      this._router.navigate(['/main/dashboard']);
    }, (err: any) => {
      console.log(err);
      const {detail} = err.error;
      Swal.fire({
        titleText: 'Upss!!',
        text: detail,
        icon:'error'
      });
      this.resetForm();
    });
  }

  resetForm = (): void => {
    this.loginForm.patchValue({
      userName: '',
      password: ''
    })
  }
}
