import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.sass']
})

export class CheckInComponent implements OnInit {


  constructor() { }

  async ngOnInit() {
    //await this.loadList();
  }


}
