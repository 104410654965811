import { Component, OnInit } from '@angular/core';
import { FlightScheduleService } from '../../services/flight-schedule.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-passengers',
  templateUrl: './list-passengers.component.html',
  styleUrls: ['./list-passengers.component.sass']
})
export class ListPassengersComponent implements OnInit {

  _id: string;
  elements: any;
  dtOptions: any = {};
  dtTrigger = new Subject();
  gouvernmet = false;

  constructor(private readonly _service: FlightScheduleService, private readonly _router: Router, private _route: ActivatedRoute) { }

  async ngOnInit() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this._id = this._route.snapshot.paramMap.get('id');
    this.elements = await this._service.getPassengersBySchedule(this._id);
    const tem: any = await this._service.isGovernment(this._id);
    if (tem.governmentContractorFlight) {
      this.gouvernmet = true;
    }
    this.dtTrigger.next();
  }
}
