import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PassengerService } from '../../services/passenger.service';
import { NationalityService } from '../../services/nationality.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})

export class CreateComponent implements OnInit {

  _id: string;
  mongoId: string;
  elements: any = [];
  modify: boolean = false;
  nationalityList: any = [];
  bookingsList: any = [];
  dtOptions: any = {};
  dtTrigger = new Subject();

  elemForm = new FormGroup({
    _id: new FormControl(''),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    dateOfBirth: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    passport: new FormControl('', Validators.required),
    isComplete: new FormControl(false),
    email: new FormControl(),
    other: new FormControl(),
    nationalityId: new FormControl(),
    emergencyName: new FormControl(),
    emergencyPhone: new FormControl()
  })

  constructor(private readonly _service: PassengerService, private readonly _service2: NationalityService, private readonly _router: Router, private _route: ActivatedRoute) { }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this._id = this._route.snapshot.paramMap.get('id');
    this.nationalityList = await this._service2.getList();
    if(this._id) {
      this.modify = true;
      const doc: any = await this._service.getOne(this._id);
      if (!doc) {
        this._router.navigate([`/passenger`]);
        Swal.fire('Passport not found', '', 'info');
      } else {
        doc.passenger['dateOfBirth'] = format(new Date(doc.passenger['dateOfBirth']), 'yyyy-MM-dd');
        this.bookingsList = doc.bookings;
        this.mongoId = doc.passenger._id;
        this.elemForm.patchValue(doc.passenger);
        this.dtTrigger.next();
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async send() {
    const form = this.elemForm.value;
    form._id = this.mongoId;
    form.dateOfBirth = new Date(form.dateOfBirth).toISOString();
    Swal.fire({
      title: 'Are your sure you want to make these changes?',
      text: 'These changes cannot be reversed',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#27509A',
      cancelButtonColor: '#C94F1B',
      confirmButtonText: 'Modify',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this._service.modify(form).then((response)=>{
          this._router.navigate([`/passenger`]);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        });
      }
    });
  }

}
