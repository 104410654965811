import { Component, OnInit } from '@angular/core';
import { LocationCombinationService } from './../../services/location-combination.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

  elements: any;
  dtOptions: any = {};
  dtTrigger = new Subject();

  constructor(private readonly _service: LocationCombinationService, private readonly _router: Router) { }

  async ngOnInit() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this.elements = await this._service.getList();
    this.dtTrigger.next();
  }

  create() {
    this._router.navigate([`/location-combination/create`]);
  }

  modify(id:string) {
    this._router.navigate([`/location-combination/modify/${id}`]);
  }

}
