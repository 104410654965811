import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CheckUserTypeGuard implements CanActivate {

  constructor(public router: Router, public http: HttpClient) {}

  canActivate(): Promise<boolean> | boolean {
    const isAdmin = (localStorage.getItem('BOOKYNGSYSTEM::admin') == 'true');
    if (!isAdmin) {
      this.router.navigate(['/main/dashboard']);
      return false;
    }
    return true
  }
}