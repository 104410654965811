import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flight-schedule',
  templateUrl: './flight-schedule.component.html',
  styleUrls: ['./flight-schedule.component.sass']
})
export class FlightScheduleComponent implements OnInit {

  constructor() { }

  async ngOnInit() {
  }

}
