import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { APP_ROUTES_MAIN } from './main.routes';
import { SharedModule } from '../shared/shared.module';
import { MainServiceModule } from './services/main.service.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//modules
import { UserModule } from './user/user.module';
import { NationalityModule } from './nationality/nationality.module';
import { LocationModule } from './location/location.module';
import { AirplaneModule } from './airplane/airplane.module';
import { LocationCombinationModule } from './location-combination/location-combination.module';
import { FlightScheduleModule } from './flight-schedule/flight-schedule.module';
import { FlightBulkScheduleModule } from './flight-bulk-schedule/flight-bulk-schedule.module';
import { BookingTicketModule } from './booking-ticket/booking-ticket.module';
import { BookingTicketWithoutPaymentModule } from './booking-ticket-without-payment/booking-ticket-without-payment.module';
import { BookingDetailsModule } from './booking-details/booking-details.module';
import { CancelBookingModule } from './cancel-booking/cancel-booking.module';
import { CancelationReportModule } from './cancelation-report/cancelation-report.module';
import { NewBookingModule } from './new-booking/new-booking-report.module';
import { IncomeReportModule } from './income-report/income-report.module';
import { VacantReportModule } from './vacant-report/vacant-report.module';

import { ChartsModule } from 'ng2-charts';
import { PassengerModule } from './passenger/passenger.module';
import { CheckInModule } from './check-in/check-in.module';

@NgModule({
  declarations: [
    MainComponent,
    DashboardComponent
  ],
  imports: [
    APP_ROUTES_MAIN,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MainServiceModule,
    UserModule,
    NationalityModule,
    LocationModule,
    AirplaneModule,
    LocationCombinationModule,
    FlightScheduleModule,
    FlightBulkScheduleModule,
    BookingTicketModule,
    BookingTicketWithoutPaymentModule,
    BookingDetailsModule,
    CancelBookingModule,
    CancelationReportModule,
    NewBookingModule,
    IncomeReportModule,
    VacantReportModule,
    ChartsModule,
    PassengerModule,
    CheckInModule
  ]
})
export class MainModule { }
