import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlightBulkScheduleService } from '../../services/flight-bulk-schedule.service';
import { LocationCombinationService } from '../../services/location-combination.service';
import { AirplaneService } from '../../services/airplane.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { format, lastDayOfMonth, eachDayOfInterval, getDay } from 'date-fns'

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  _id: string;
  elements: any = [];
  modify: boolean = false;
  otherList: any = [];
  otherList2: any = [];
  arrayDays: any = [];
  checketDays: any = [];
  airplane: any;
  years: any = [
    {_id:2020},{_id:2021},{_id:2022},{_id:2023},{_id:2024}
  ];
  months: any = [
    {_id: 1,name:'January'},
    {_id: 2,name:'February'},
    {_id: 3,name:'March'},
    {_id: 4,name:'April'},
    {_id: 5,name:'May'},
    {_id: 6,name:'June'},
    {_id: 7,name:'July'},
    {_id: 8,name:'August'},
    {_id: 9,name:'September'},
    {_id: 10,name:'October'},
    {_id: 11,name:'November'},
    {_id: 12,name:'December'},
  ];

  selectedMonth = false;
  elemForm = new FormGroup({
    locationCombinationId: new FormControl('', Validators.required),
    airplaneId: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    month: new FormControl('', Validators.required),
    days: new FormControl(''),
    time: new FormControl('', Validators.required),
    carryOnBaggage: new FormControl('', Validators.required),
    checkedBaggage: new FormControl('', Validators.required),
    durationAprox: new FormControl('', Validators.required),
    priceEconomy: new FormControl(0, [Validators.required, Validators.min(0)]),
    priceBusiness: new FormControl(0, [Validators.required, Validators.min(0)]),
    isActive: new FormControl(true),
    taxes: new FormControl(0, Validators.required)
  })

  constructor(private readonly _service: FlightBulkScheduleService, private readonly _service2: LocationCombinationService, private readonly _service3: AirplaneService, private readonly _router: Router,   private _route: ActivatedRoute) { }

  async ngOnInit() {
    this._id = this._route.snapshot.paramMap.get('id');
    this.otherList = await this._service2.getList();
    this.otherList2 = await this._service3.getList();
    if(this._id) {
      this.modify = true;
      const doc: any = await this._service.getOne(this._id);
      doc.date = format(new Date(doc.date), "yyyy-MM-dd'T'HH:mm");
      this.elemForm.patchValue(doc);
    }
  }

  async send() {
    const form = this.elemForm.value;
    form.days = this.checketDays;
    form.year = parseInt(form.year);
    form.month = parseInt(form.month) - 1;
    form.availableSeatsEconomy = (this.airplane) ? this.airplane.seatsEconomy : 0;
    form.availableSeatsBusiness = (this.airplane) ? this.airplane.seatsBusiness : 0;
    if(this._id && this.modify) {
      Swal.fire({
        title: 'Are your sure you want to make these changes?',
        text: 'These changes cannot be reversed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Modify',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          form._id = this._id;
          this._service.modify(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/flight-schedule`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure to create a new bulk flight schedule?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          this._service.create(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/flight-schedule`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    }
  }

  changeDays() {
    this.arrayDays = [];
    const form = this.elemForm.value;
    if (form.year && form.month) {
      const {year, month} = form;
      let lastDay: any = lastDayOfMonth(new Date(`${year}/${month}/01`));
      lastDay = format(lastDay, 'dd');
      const intervalDays: any = eachDayOfInterval({ start: new Date(`${year}/${month}/01`), end: new Date(`${year}/${month}/${lastDay}`) });
      const firstDay = getDay(new Date(intervalDays[0]));
      let cont = this.fixWeek(firstDay);
      this.arrayDays.push({_id: null,day: null},{_id: null,day: null});
      for (const i in intervalDays) {
        this.arrayDays.push({
          _id: parseInt(i) + 1,
          day: getDay(new Date(intervalDays[i]))
        })
        cont++
        if (cont >= 7) {
          this.arrayDays.push({_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null});
          cont=0;
        }
      }
    }
  }

  fixWeek(firstDay: any): number {
    switch(firstDay) {
      case 1:
        this.arrayDays.unshift({
          _id: null,
          day: null
        })
        return 1
      case 2:
        this.arrayDays.unshift(
          {_id: null,day: null},{_id: null,day: null}
        )
        return 2
      case 3:
        this.arrayDays.unshift(
          {_id: null,day: null},{_id: null,day: null},{_id: null,day: null}
        )
        return 3
      case 4:
        this.arrayDays.unshift(
          {_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null}
        )
        return 4
      case 5:
        this.arrayDays.unshift(
          {_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null}
        )
        return 5
      case 6:
        this.arrayDays.unshift(
          {_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null},{_id: null,day: null}
        )
        return 6
      default:
        return 0
    }
  }

  checkADay(id: number) {
    let validate = true;
    let temporal: any = [];
    for (var i of this.checketDays) {
      if (parseInt(i) == id) {
        validate = false;
      } else {
        temporal.push(parseInt(i));
      }
    }
    if (validate) {
      temporal.push(id);
    }
    this.checketDays = temporal.sort(function(a,b){return a - b;});
  }

  resetAll() {
    this._id = null;
    this.modify = false;
    this.arrayDays = [];
    this.checketDays = [];
    this.years = [
      {_id:2020},{_id:2021},{_id:2022},{_id:2023},{_id:2024}
    ];
    this.months = [
      {_id: 1,name:'January'},
      {_id: 2,name:'February'},
      {_id: 3,name:'March'},
      {_id: 4,name:'April'},
      {_id: 5,name:'May'},
      {_id: 6,name:'June'},
      {_id: 7,name:'July'},
      {_id: 8,name:'August'},
      {_id: 9,name:'September'},
      {_id: 10,name:'October'},
      {_id: 11,name:'November'},
      {_id: 12,name:'December'},
    ];
    this.elemForm.reset();
  }

  changeAirplane() {
    const {airplaneId} = this.elemForm.value;
    for (const i of this.otherList2) {
      if (airplaneId == i._id) {
        this.airplane = i;
      }
    }
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('password2').value;
    return pass === confirmPass ? null : { notSame: true }
  }
}
