import { Component, OnInit } from '@angular/core';
import { UserService } from './../../services/user.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

  elements: any;
  dtOptions: any = {};
  dtTrigger = new Subject();

  constructor(private readonly _userService: UserService, private readonly _router: Router) { }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this.elements = await this._userService.getList();
    this.dtTrigger.next();
  }

  create() {
    this._router.navigate([`/user/create`]);
  }

  modify(id:string) {
    this._router.navigate([`/user/modify/${id}`]);
  }

}
