import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingTicketComponent } from './booking-ticket-without-payment.component';
import { APP_ROUTES_MAIN } from './booking-ticket-without-payment.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    BookingTicketComponent,
    CreateComponent,
    ListComponent
  ],
  imports: [
    APP_ROUTES_MAIN,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class BookingTicketWithoutPaymentModule { }
