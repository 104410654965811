import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';

@Component({
  selector: 'app-nav-bar-transaction',
  templateUrl: './nav-bar-transaction.component.html',
  styleUrls: ['./nav-bar-transaction.component.sass']
})
export class NavBarTransaction implements OnInit {

  isAdmin: any = false;
  constructor(private readonly _authService: AuthService) { }

  ngOnInit() {
    this.isAdmin = (localStorage.getItem('BOOKYNGSYSTEM::admin') == 'true');
  }

  logout() {
    this._authService.logout()
  }

}
