import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AirplaneService } from './../../services/airplane.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  _id: string;
  elements: any = [];
  modify: boolean = false;

  elemForm = new FormGroup({
    code: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    model: new FormControl('', Validators.required),
    seats: new FormControl('', Validators.required),
    isActive: new FormControl(true)
  })

  constructor(private readonly _service: AirplaneService, private readonly _router: Router,   private _route: ActivatedRoute) { }

  async ngOnInit() {
    this._id = this._route.snapshot.paramMap.get('id');
    if(this._id) {
      this.modify = true;
      const doc = await this._service.getOne(this._id)
      this.elemForm.patchValue(doc);
    }
  }

  async send() {
    const form = this.elemForm.value;
    if(this._id && this.modify) {
      Swal.fire({
        title: 'Are your sure you want to make these changes?',
        text: 'These changes cannot be reversed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Modify',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          form._id = this._id;
          this._service.modify(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/airplane`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure to create a new airplane?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          this._service.create(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/airplane`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    }
  }

  resetAll() {
    this._id = null;
    this.modify = false;
    this.elemForm.reset();
  }
}
