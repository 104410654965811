import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

  elements: any;
  dtOptions: any = {};
  dtTrigger = new Subject();
  elemForm = new FormGroup({
    passport: new FormControl('', Validators.required)
  })

  constructor(private readonly _router: Router) { }

  async ngOnInit() {
  }

  find() {
    const {passport} = this.elemForm.value
    this._router.navigate([`/passenger/modify/${passport}`]);
  }

  resetAll() {
    this.elements = [];
    this.elemForm.reset();
  }

}
