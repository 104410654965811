import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  url: string = 'booking';

  constructor(private http: HttpClient) { }

  getList() {
    return this.http.get(`${environment.urlApi}/${this.url}`).toPromise();
  }

  search(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}/search`, body).toPromise();
  }

  getDashboard() {
    return this.http.get(`${environment.urlApi}/${this.url}/dashboard`).toPromise();
  }

  cancel(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}/cancel`, body).toPromise();
  }

  income(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}/income`, body).toPromise();
  }

  newBooking() {
    return this.http.post(`${environment.urlApi}/${this.url}/new`, {}).toPromise();
  }

  getOneByCode(id: string) {
    return this.http.get(`${environment.urlApi}/${this.url}/code/${id}`).toPromise();
  }

  getOne(id: string) {
    return this.http.get(`${environment.urlApi}/${this.url}/${id}`).toPromise();
  }

  create(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}`, body).toPromise();
  }

  modify(body: any) {
    return this.http.put(`${environment.urlApi}/${this.url}`, body).toPromise();
  }

}
