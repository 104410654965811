import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from './../../services/user.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  _id: string;
  elements: any = [];
  modify: boolean = false;

  userForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    password2: new FormControl('', Validators.required),
    employName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    isAdmin: new FormControl(false),
    isActive: new FormControl(true)
  }, {validators: this.checkPasswords})

  constructor(private readonly _userService: UserService, private readonly _router: Router,   private _route: ActivatedRoute) { }

  async ngOnInit() {
    this._id = this._route.snapshot.paramMap.get('id');
    if(this._id) {
      this.modify = true;
      const doc = await this._userService.getOne(this._id)
      this.userForm.patchValue(doc);
    }
  }

  async send() {
    const form = this.userForm.value;
    if(this._id && this.modify) {
      Swal.fire({
        title: 'Are your sure you want to make these changes?',
        text: 'These changes cannot be reversed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Modify',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          form._id = this._id;
          this._userService.modify(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/user`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure to create a new user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#27509A',
        cancelButtonColor: '#C94F1B',
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          this._userService.create(form).then((response)=>{
            this.resetAll();
            this._router.navigate([`/user`]);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    }
  }


  resetAll() {
    this._id = null;
    this.modify = false;
    this.userForm.reset();
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('password2').value;
    return pass === confirmPass ? null : { notSame: true }
  }

}
