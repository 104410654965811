import { Component, OnInit } from '@angular/core';
import { FlightScheduleService } from '../../services/flight-schedule.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

  elements: any;
  dtOptions: any = {};
  dtTrigger = new Subject();

  constructor(private readonly _service: FlightScheduleService, private readonly _router: Router) { }

  async ngOnInit() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this.elements = await this._service.getAvailableSeats();
    console.log(this.elements);
    this.dtTrigger.next();
  }
}
