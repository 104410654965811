import { Routes, RouterModule } from '@angular/router';
import { CheckSessionGuard } from '../../shared/services/guards/session.guards';
import { BookingDetailsComponent } from './booking-details.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';

const appRoutes: Routes = [
  {
    path: 'booking-details',
    component: BookingDetailsComponent,
    children: [
      {
        path: 'list',
        component: ListComponent,
        canActivate: [ CheckSessionGuard ]
      },
      {
        path: 'create',
        component: CreateComponent,
        canActivate: [ CheckSessionGuard ]
      },
      {
        path: 'modify/:id',
        component: CreateComponent,
        canActivate: [ CheckSessionGuard ]
      },
      {
        path: '',
        redirectTo: '/booking-details/list',
        pathMatch: 'full'
      }
    ]
  }
];

export const APP_ROUTES_MAIN = RouterModule.forChild(appRoutes);
