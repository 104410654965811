import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../../shared/services/interceptors/jwt.interceptor';
import { MainService } from './main.service';
import { UserService } from './user.service';
import { NationalityService } from './nationality.service';
import { LocationService } from './location.service';
import { AirplaneService } from './airplane.service';
import { LocationCombinationService } from './location-combination.service';
import { FlightScheduleService } from './flight-schedule.service';
import { FlightBulkScheduleService } from './flight-bulk-schedule.service';
import { BookingService } from './booking.service';
import { PassengerService } from './passenger.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [],
    providers: [
        MainService,
        UserService,
        NationalityService,
        LocationService,
        AirplaneService,
        LocationCombinationService,
        FlightScheduleService,
        FlightBulkScheduleService,
        BookingService,
        PassengerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        }
    ]
})
export class MainServiceModule { }
