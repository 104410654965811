import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../auth/services/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.sass']
})
export class NavBarComponent implements OnInit {

  isAdmin: any = false;
  constructor(private readonly _authService: AuthService) { }

  ngOnInit() {
    this.isAdmin = (localStorage.getItem('BOOKYNGSYSTEM::admin') == 'true');
  }

  logout() {
    this._authService.logout()
  }

}
