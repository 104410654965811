import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CheckSessionGuard implements CanActivate {

    constructor(public router: Router, public http: HttpClient) {}

    canActivate(): Promise<boolean> | boolean {
        const token = localStorage.getItem('BOOKYNGSYSTEM::token');
        if (!token) {
            this.router.navigate(['/auth/login']);
            return false;
        }
        const payload: any = JSON.parse(atob(token.split('.')[1]));
        if ( this.check_expiress(payload.exp) ) {
            localStorage.clear();
            Swal.fire('Finalización de Sesion', 'Su sesión ha caducado, favor vuelva a ingresar', 'error' );
            this.router.navigate(['/auth/login']);
            return false;
        }
        return this.check_reload_token(payload.exp);
    }

    check_reload_token( dateExpired: number ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const tokenExp = new Date( dateExpired * 1000 );
            const nowDate = new Date();
            nowDate.setTime(nowDate.getTime() + ( 5 * 60 * 1000));
            if ( tokenExp.getTime() > nowDate.getTime() ) {
                resolve(true);
            } else {
                resolve(true);
            }
        });
    }

    check_expiress( dateExpired: number) {
        const nowDate = new Date().getTime() / 1000 ;
        return dateExpired < nowDate;
    }
}