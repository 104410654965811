import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.sass']
})

export class BookingDetailsComponent implements OnInit {


  constructor() { }

  async ngOnInit() {
    //await this.loadList();
  }


}
