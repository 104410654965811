import { Component, OnInit } from '@angular/core';
import { FlightBulkScheduleService } from '../../services/flight-bulk-schedule.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

  elements: any;
  dtOptions: any = {};
  dtTrigger = new Subject();
  months: any = {
    1:'January',
    2:'February',
    3:'March',
    4:'April',
    5:'May',
    6:'June',
    7:'July',
    8:'August',
    9:'September',
    10:'October',
    11:'November',
    12:'December'
  };

  constructor(private readonly _service: FlightBulkScheduleService, private readonly _router: Router) { }

  async ngOnInit() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this.elements = await this._service.getList();
    this.dtTrigger.next();
  }

  create() {
    this._router.navigate([`/flight-bulk-schedule/create`]);
  }

  goList() {
    this._router.navigate([`/flight-schedule`]);
  }

}
