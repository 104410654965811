import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { AirplaneService } from "./../../services/airplane.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.sass"],
})
export class CreateComponent implements OnInit {
  _id: string;
  elements: any = [];
  modify: boolean = false;
  seatingOrganization: any = false;
  imgPerfilDisplay: any;
  imgPerfilData: any;
  tempImg: any;

  elemForm = new FormGroup({
    code: new FormControl("", Validators.required),
    name: new FormControl("", Validators.required),
    model: new FormControl("", Validators.required),
    columns: new FormControl(""),
    img: new FormControl(""),
    seatsEconomy: new FormControl("", Validators.required),
    seatsBusiness: new FormControl(0, Validators.required),
    isActive: new FormControl(true),
  });
  modelList = [
    {
      "identify":"Boeing737",
      "name":"Boeing 737",
      "image":"/assets/aviones-modelo/es-mapa-avion-con-asientos.png"
    },
    {
      "identify":"Boeing737-2",
      "name":"Boeing 737 - 2",
      "image":"/assets/aviones-modelo/es-mapa-boeing737-2-con-asientos.png"
    },
    // {
    //   "identify":"ACJ320",
    //   "name":"ACJ 320",
    //   "image":"/assets/aviones-modelo/es-mapa-acj320-con-asientos.png"
    // },
    {
      identify: "ACJ321",
      name: "ACJ 321",
      image: "/assets/aviones-modelo/es-mapa-acj321-con-asientos.png",
    },
    // {
    //   "identify":"ACJ330",
    //   "name":"ACJ 330",
    //   "image":"/assets/aviones-modelo/es-mapa-acj330-con-asientos.png"
    // },
    // {
    //   "identify":"ACJ340",
    //   "name":"ACJ 340",
    //   "image":"/assets/aviones-modelo/es-mapa-acj340-con-asientos.png"
    // },
    // {
    //   "identify":"ACJ340v1",
    //   "name":"ACJ 340 V1",
    //   "image":"/assets/aviones-modelo/es-mapa-acj340v1-con-asientos.png"
    // },
    {
      "identify":"ACJ350",
      "name":"ACJ 350",
      "image":"/assets/aviones-modelo/es-mapa-acj350-con-asientos.png"
    },
    {
      identify: "AirbusA319-100",
      name: "Airbus A319 - 100",
      image: "/assets/aviones-modelo/es-mapa-a319-100-con-asientos.png",
    },
    {
      identify: "ANTONOV148",
      name: "ANTONOV 148",
      image: "/assets/aviones-modelo/es-mapa-antoov148-con-asientos.png",
    },
    {
      identify: "ATR42-600",
      name: "ATR42 - 600",
      image: "/assets/aviones-modelo/es-mapa-atr42-600-con-asientos.png",
    },
    {
      identify: "ATR72-600",
      name: "ATR72 - 600",
      image: "/assets/aviones-modelo/es-mapa-atr72-600-con-asientos.png",
    },
    {
      identify: "BA146",
      name: "BA 146",
      image: "/assets/aviones-modelo/es-mapa-ba146-con-asientos.png",
    },
    // {
    //   "identify":"DC9",
    //   "name":"DC 9",
    //   "image":"/assets/aviones-modelo/es-mapa-dc9-con-asientos.png"
    // },
    {
      identify: "Embraer145",
      name: "Embraer145",
      image: "/assets/aviones-modelo/es-mapa-embraer145-con-asientos.png",
    },
    {
      identify: "Jet328",
      name: "Jet 328",
      image: "/assets/aviones-modelo/es-mapa-jet328-con-asientos.png",
    },
    {
      identify: "Airbus320-200",
      name: "Airbus 320-200",
      image: "/assets/aviones-modelo/es-mapa-a320-200-con-asientos.png",
    },
    {
      identify: "Airbus321-211",
      name: "Airbus 321-211",
      image: "/assets/aviones-modelo/es-mapa-a321-211-con-asientos.png",
    },
    {
      identify: "CRJ-700",
      name: "CRJ-700",
      image: "/assets/aviones-modelo/es-mapa-CRJ-700-con-asientos.png",
    },
    {
      identify: "CRJ-900",
      name: "CRJ-900",
      image: "/assets/aviones-modelo/es-mapa-CRJ-900-con-asientos.png",
    },
    {
      identify: "EmbraerE-190",
      name: "EmbraerE-190",
      image: "/assets/aviones-modelo/es-mapa-EmbraerE-190-con-asientos.png",
    },
  ];

  constructor(
    private readonly _service: AirplaneService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this._id = this._route.snapshot.paramMap.get("id");
    if (this._id) {
      this.modify = true;
      const doc: any = await this._service.getOne(this._id);
      this.tempImg = doc.img;
      this.imgPerfilDisplay = this.tempImg;
      delete doc.img;
      const columns = doc.seatingOrganization;
      doc.columns = columns.length;
      this.elemForm.patchValue(doc);
      // this.changeColumns();
      this.seatingOrganization.patchValue(doc.seatingOrganization);
    }
  }

  async send() {
    const form = this.elemForm.value;
    form.seatingOrganization = this.seatingOrganization;
    // console.log(form);
    if (this._id && this.modify) {
      Swal.fire({
        title: "Are your sure you want to make these changes?",
        text: "These changes cannot be reversed",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#27509A",
        cancelButtonColor: "#C94F1B",
        confirmButtonText: "Modify",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          form._id = this._id;
          this._service.modify(form).then((response) => {
            this.resetAll();
            this._router.navigate([`/airplane`]);
            Swal.fire({
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure to create a new aircraft?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#27509A",
        cancelButtonColor: "#C94F1B",
        confirmButtonText: "Create",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this._service.create(form).then((response) => {
            this.resetAll();
            this._router.navigate([`/airplane`]);
            Swal.fire({
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    }
  }

  // changeColumns() {
  //   this.seatingOrganization = new FormArray([]);
  //   const {columns} = this.elemForm.value;
  //   const arrayLetters = ['A','B','C','D','E','F','G'];
  //   for (let i = 0; i<parseInt(columns); i++) {
  //     this.seatingOrganization.controls.push(
  //       new FormGroup({
  //         colum: new FormControl(arrayLetters[i], Validators.required),
  //         from: new FormControl(1, [Validators.min(0)]),
  //         to: new FormControl('', [Validators.min(0)]),
  //         class: new FormControl('0')
  //       })
  //     );
  //   }
  // }

  selectModel() {
    const { model } = this.elemForm.value;
    for (var i of this.modelList) {
      if (i.identify == model) {
        this.imgPerfilDisplay = i.image;
        this.fillSeats(model);
      }
    }
  }

  fillSeats(model: string) {
    let seatingOrganization = {};
    switch (model) {
      case "Boeing737":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "32",
            class: "0",
          },
          {
            colum: "B",
            from: "1",
            to: "32",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "32",
            class: "0",
          },
          {
            colum: "D",
            from: "2",
            to: "32",
            class: "0",
          },
          {
            colum: "E",
            from: "2",
            to: "32",
            class: "0",
          },
          {
            colum: "F",
            from: "2",
            to: "32",
            class: "0",
          },
        ];
        break;
      case "ACJ320":
        seatingOrganization = [
          {
            colum: "A",
            from: "11",
            to: "38",
            class: "0",
          },
          {
            colum: "A",
            from: "4",
            to: "10",
            class: "1",
          },
          {
            colum: "A",
            from: "1",
            to: "3",
            class: "2",
          },
          {
            colum: "B",
            from: "11",
            to: "38",
            class: "0",
          },
          {
            colum: "B",
            from: "4",
            to: "10",
            class: "1",
          },
          {
            colum: "B",
            from: "1",
            to: "3",
            class: "2",
          },
          {
            colum: "C",
            from: "11",
            to: "38",
            class: "0",
          },
          {
            colum: "C",
            from: "4",
            to: "10",
            class: "1",
          },
          {
            colum: "D",
            from: "11",
            to: "38",
            class: "0",
          },
          {
            colum: "D",
            from: "4",
            to: "10",
            class: "1",
          },
          {
            colum: "E",
            from: "11",
            to: "38",
            class: "0",
          },
          {
            colum: "E",
            from: "4",
            to: "10",
            class: "1",
          },
          {
            colum: "E",
            from: "1",
            to: "3",
            class: "2",
          },
          {
            colum: "F",
            from: "11",
            to: "38",
            class: "0",
          },
          {
            colum: "F",
            from: "4",
            to: "10",
            class: "1",
          },
          {
            colum: "F",
            from: "1",
            to: "3",
            class: "2",
          },
        ];
        break;
      case "ACJ321":
        seatingOrganization = [
          {
            colum: "A",
            from: "4",
            to: "7",
            class: "0",
          },
          {
            colum: "A",
            from: "9",
            to: "20",
            class: "0",
          },
          {
            colum: "A",
            from: "22",
            to: "32",
            class: "0",
          },
          {
            colum: "B",
            from: "4",
            to: "32",
            class: "0",
          },
          {
            colum: "C",
            from: "4",
            to: "32",
            class: "0",
          },
          {
            colum: "D",
            from: "4",
            to: "32",
            class: "0",
          },
          {
            colum: "E",
            from: "4",
            to: "32",
            class: "0",
          },
          {
            colum: "F",
            from: "4",
            to: "7",
            class: "0",
          },
          {
            colum: "F",
            from: "9",
            to: "20",
            class: "0",
          },
          {
            colum: "F",
            from: "22",
            to: "32",
            class: "0",
          },
          {
            colum: "A",
            from: "1",
            to: "3",
            class: "1",
          },
          {
            colum: "B",
            from: "1",
            to: "3",
            class: "1",
          },
          {
            colum: "E",
            from: "1",
            to: "3",
            class: "1",
          },
          {
            colum: "F",
            from: "1",
            to: "3",
            class: "1",
          },
        ];
        break;
      case "ACJ330":
        return false;
        break;
      case "ACJ340":
        return false;
        break;
      case "ACJ340v1":
        return false;
      break;
      case 'ACJ340v1':
        return false
      break;
      case 'ACJ350':
        seatingOrganization = [
          {
            colum:'A',
            from:'1',
            to:'9',
            class:'1'
          },
          {
            colum:'E',
            from:'1',
            to:'9',
            class:'1'
          },
          {
            colum:'F',
            from:'1',
            to:'9',
            class:'1'
          },
          {
            colum:'K',
            from:'1',
            to:'9',
            class:'1'
          },
          {
            colum:'A',
            from:'16',
            to:'43',
            class:'0'
          },
          {
            colum:'B',
            from:'16',
            to:'43',
            class:'0'
          },
          {
            colum:'C',
            from:'17',
            to:'43',
            class:'0'
          },
          {
            colum:'D',
            from:'17',
            to:'44',
            class:'0'
          },
          {
            colum:'E',
            from:'17',
            to:'44',
            class:'0'
          },
          {
            colum:'F',
            from:'17',
            to:'44',
            class:'0'
          },
          {
            colum:'H',
            from:'17',
            to:'43',
            class:'0'
          },
          {
            colum:'J',
            from:'16',
            to:'43',
            class:'0'
          },
          {
            colum:'K',
            from:'16',
            to:'43',
            class:'0'
          }
        ]
      break;
      case 'AirbusA319-100':
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "26",
            class: "0",
          },
          {
            colum: "B",
            from: "1",
            to: "26",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "26",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "26",
            class: "0",
          },
          {
            colum: "E",
            from: "1",
            to: "26",
            class: "0",
          },
          {
            colum: "F",
            from: "1",
            to: "26",
            class: "0",
          },
        ];
        break;
      case "ANTONOV148":
        seatingOrganization = [
          {
            colum: "A",
            from: "2",
            to: "17",
            class: "0",
          },
          {
            colum: "B",
            from: "2",
            to: "17",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "17",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "17",
            class: "0",
          },
          {
            colum: "E",
            from: "1",
            to: "17",
            class: "0",
          },
        ];
        break;
      case "ATR42-600":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "18",
            class: "0",
          },
          {
            colum: "B",
            from: "1",
            to: "18",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "17",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "17",
            class: "0",
          },
        ];
        break;
      case "ATR72-600":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "19",
            class: "0",
          },
          {
            colum: "B",
            from: "1",
            to: "19",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "19",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "19",
            class: "0",
          },
        ];
        break;
      case "BA146":
        seatingOrganization = [
          {
            colum: "A",
            from: "2",
            to: "21",
            class: "0",
          },
          {
            colum: "B",
            from: "2",
            to: "21",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "21",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "21",
            class: "0",
          },
          {
            colum: "E",
            from: "1",
            to: "21",
            class: "0",
          },
        ];
        break;
      case "DC9":
        seatingOrganization = [
          {
            colum: "A",
            from: "5",
            to: "19",
            class: "0",
          },
          {
            colum: "A",
            from: "2",
            to: "3",
            class: "1",
          },
          {
            colum: "C",
            from: "5",
            to: "19",
            class: "0",
          },
          {
            colum: "C",
            from: "2",
            to: "3",
            class: "1",
          },
          {
            colum: "D",
            from: "5",
            to: "18",
            class: "0",
          },
          {
            colum: "D",
            from: "2",
            to: "3",
            class: "1",
          },
          {
            colum: "E",
            from: "5",
            to: "18",
            class: "0",
          },
          {
            colum: "F",
            from: "5",
            to: "18",
            class: "0",
          },
          {
            colum: "F",
            from: "2",
            to: "3",
            class: "1",
          },
        ];
        break;
      case "Embraer145":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "24",
            class: "0",
          },
          {
            colum: "C",
            from: "3",
            to: "24",
            class: "0",
          },
          {
            colum: "D",
            from: "3",
            to: "24",
            class: "0",
          },
        ];
        break;
      case "Jet328":
        seatingOrganization = [
          {
            colum: "A",
            from: "2",
            to: "12",
            class: "0",
          },
          {
            colum: "B",
            from: "12",
            to: "12",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "10",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "10",
            class: "0",
          },
        ];
        break;
      case "Airbus320-200":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "10",
            class: "0",
          },
          {
            colum: "A",
            from: "12",
            to: "23",
            class: "0",
          },
          {
            colum: "A",
            from: "25",
            to: "30",
            class: "0",
          },
          {
            colum: "B",
            from: "1",
            to: "30",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "30",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "30",
            class: "0",
          },
          {
            colum: "E",
            from: "1",
            to: "30",
            class: "0",
          },
          {
            colum: "F",
            from: "1",
            to: "10",
            class: "0",
          },
          {
            colum: "F",
            from: "12",
            to: "23",
            class: "0",
          },
          {
            colum: "F",
            from: "25",
            to: "30",
            class: "0",
          }
        ];
        break;
      case "Airbus321-211":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "9",
            class: "0",
          },
          {
            colum: "A",
            from: "11",
            to: "23",
            class: "0",
          },
          {
            colum: "A",
            from: "25",
            to: "36",
            class: "0",
          },
          {
            colum: "B",
            from: "1",
            to: "36",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "36",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "37",
            class: "0",
          },
          {
            colum: "E",
            from: "1",
            to: "37",
            class: "0",
          },
          {
            colum: "F",
            from: "1",
            to: "9",
            class: "0",
          },
          {
            colum: "F",
            from: "11",
            to: "23",
            class: "0",
          },
          {
            colum: "F",
            from: "25",
            to: "37",
            class: "0",
          }
        ];
        break;
      case "CRJ-700":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "3",
            class: "1",
          },
          {
            colum: "C",
            from: "1",
            to: "3",
            class: "1",
          },
          {
            colum: "D",
            from: "1",
            to: "3",
            class: "1",
          },
          {
            colum: "A",
            from: "4",
            to: "18",
            class: "0",
          },
          {
            colum: "B",
            from: "4",
            to: "18",
            class: "0",
          },
          {
            colum: "C",
            from: "4",
            to: "18",
            class: "0",
          },
          {
            colum: "D",
            from: "4",
            to: "18",
            class: "0",
          }
        ];
        break;
      case "CRJ-900":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "4",
            class: "1",
          },
          {
            colum: "B",
            from: "1",
            to: "4",
            class: "1",
          },
          {
            colum: "C",
            from: "1",
            to: "4",
            class: "1",
          },
          {
            colum: "A",
            from: "5",
            to: "19",
            class: "0",
          },
          {
            colum: "B",
            from: "5",
            to: "19",
            class: "0",
          },
          {
            colum: "C",
            from: "5",
            to: "18",
            class: "0",
          },
          {
            colum: "D",
            from: "5",
            to: "18",
            class: "0",
          }
        ];
        break;
      case "EmbraerE-190":
        seatingOrganization = [
          {
            colum: "A",
            from: "2",
            to: "2",
            class: "1",
          },
          {
            colum: "B",
            from: "2",
            to: "2",
            class: "1",
          },
          {
            colum: "C",
            from: "1",
            to: "2",
            class: "1",
          },
          {
            colum: "D",
            from: "1",
            to: "2",
            class: "1",
          },
          {
            colum: "A",
            from: "3",
            to: "27",
            class: "0",
          },
          {
            colum: "B",
            from: "3",
            to: "27",
            class: "0",
          },
          {
            colum: "C",
            from: "3",
            to: "27",
            class: "0",
          },
          {
            colum: "D",
            from: "3",
            to: "27",
            class: "0",
          }
        ];
        break;
      case "Boeing737-2":
        seatingOrganization = [
          {
            colum: "A",
            from: "1",
            to: "27",
            class: "0",
          },
          {
            colum: "B",
            from: "1",
            to: "27",
            class: "0",
          },
          {
            colum: "C",
            from: "1",
            to: "27",
            class: "0",
          },
          {
            colum: "D",
            from: "1",
            to: "27",
            class: "0",
          },
          {
            colum: "E",
            from: "1",
            to: "27",
            class: "0",
          },
          {
            colum: "F",
            from: "1",
            to: "27",
            class: "0",
          }
        ];
        break;
      default:
        return false;
    }
    this.seatingOrganization = seatingOrganization;
  }

  getControlsValue(controls: any) {
    const newArray = [];
    for (const i of controls) {
      newArray.push(i.value);
    }
    return newArray;
  }

  resetAll() {
    this._id = null;
    this.modify = false;
    this.elemForm.reset();
  }
}
