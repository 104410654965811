import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { BookingService } from '../../services/booking.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  elements: any;
  otherList: any;
  dtOptions: any = {};
  dtTrigger = new Subject();

  elemForm = new FormGroup({
    fromLocation: new FormControl('', Validators.required),
    toLocation: new FormControl('', Validators.required),
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required)
  })

  constructor(private readonly _service: LocationService, private readonly _bookingService: BookingService, private readonly _router: Router) { }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this.otherList = await this._service.getList();
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async send() {
    const doc = this.elemForm.value;
    this.elements = await this._bookingService.cancel(doc);
    this.rerender();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  resetAll() {
    this.elements = [];
    this.elemForm.reset();
    this.rerender();
  }

}
