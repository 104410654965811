import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

  token: string;

  constructor(public http: HttpClient, public router: Router) {
    this.loadStorage();
  }

  login(credentials: any) {
    return this.http.post(`${environment.urlApi}/auth`, credentials).pipe(
      map((resp: any) => {
        localStorage.setItem('BOOKYNGSYSTEM::token', resp.token);
        localStorage.setItem('BOOKYNGSYSTEM::admin', resp.isAdmin);
        this.loadStorage();
        this.router.navigate(['/main/dashboard']);
        return true;
      })
    );
  }

  isLogIn() {
    return ( this.token.length > 5 ) ? true : false ;
  }

  logout() {
    localStorage.clear();
    this.loadStorage();
    this.router.navigate(['/auth/login']);
    Swal.fire({
      title:'Thanks, come back soon',
      icon:'success'
    });
  }

  loadStorage() {
    if ( localStorage.getItem('BOOKYNGSYSTEM::token') ) {
      this.token = localStorage.getItem('BOOKYNGSYSTEM::token');
    } else {
      this.token = '';
    }
  }

}
