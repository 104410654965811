import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

    token: string;

    constructor() {	}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadStorage();
        if ( this.token && this.token.length > 1 ) {
            request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${this.token}`) });
        }
        const bodyPost: any = request.body;
        if ( bodyPost instanceof FormData ) {
            request.headers.append('Content-Type', 'multipart/form-data');
        } else {
            if (!request.headers.has('Content-Type')) {
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }
            if (!request.headers.has('Accept')) {
                request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
            }
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if ( event.status !== 200 && event.status !== 201 ) {
                        Swal.fire('Importante', 'Ha ocurrido un error al obtener la información del servidor' , 'error');
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const data: any = {
                    reason: error && error.error.detail ? error.error.detail : 'Ha ocurrido un error al obtener la información del servidor',
                    status: error.status
                };
                Swal.fire('Importante', data.reason , 'error');
                return throwError(error);
            }));
    }

    loadStorage() {
        if ( localStorage.getItem('BOOKYNGSYSTEM::token') ) {
            this.token = localStorage.getItem('BOOKYNGSYSTEM::token');
        } else {
            this.token = '';
        }
    }
}
