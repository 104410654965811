import { Routes, RouterModule } from '@angular/router';
import { CheckSessionGuard } from '../../shared/services/guards/session.guards';
import { CheckInComponent } from './check-in.component';
import { ListComponent } from './list/list.component';

const appRoutes: Routes = [
  {
    path: 'check-in',
    component: CheckInComponent,
    children: [
      {
        path: 'list',
        component: ListComponent,
        canActivate: [ CheckSessionGuard ]
      },
      {
        path: '',
        redirectTo: '/check-in/list',
        pathMatch: 'full'
      }
    ]
  }
];

export const APP_ROUTES_MAIN = RouterModule.forChild(appRoutes);
