import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CheckSessionGuard } from '../shared/services/guards/session.guards';
import { UserComponent } from './user/user.component';
import { NationalityComponent } from './nationality/nationality.component';
import { LocationComponent } from './location/location.component';
import { AirplaneComponent } from './airplane/airplane.component';
import { LocationCombinationComponent } from './location-combination/location-combination.component';

const appRoutes: Routes = [
    {
        path: 'main',
        component: MainComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [ CheckSessionGuard ]
            },
            {
                path: 'user',
                component: UserComponent,
                canActivate: [ CheckSessionGuard ]
            },
            {
                path: 'nationality',
                component: NationalityComponent,
                canActivate: [ CheckSessionGuard ]
            },
            {
                path: 'location',
                component: LocationComponent,
                canActivate: [ CheckSessionGuard ]
            },
            {
                path: 'airplane',
                component: AirplaneComponent,
                canActivate: [ CheckSessionGuard ]
            },
            {
                path: 'location-combination',
                component: LocationCombinationComponent,
                canActivate: [ CheckSessionGuard ]
            },
            {
                path: '',
                redirectTo: '/main/dashboard',
                pathMatch: 'full'
            },
        ]
    }
];

export const APP_ROUTES_MAIN = RouterModule.forChild(appRoutes);
