import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AirplaneService } from '../services/airplane.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-airplane',
  templateUrl: './airplane.component.html',
  styleUrls: ['./airplane.component.sass']
})
export class AirplaneComponent implements OnInit {

  _id: string;
  elements: any = [];
  modify: boolean = false;

  elemForm = new FormGroup({
    code: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    model: new FormControl('', Validators.required),
    seats: new FormControl('', Validators.required),
    isActive: new FormControl(true)
  })

  constructor(private readonly _service: AirplaneService) { }

  async ngOnInit() {
    //await this.loadList();
  }

  async send() {
    const form = this.elemForm.value;
    if(this._id && this.modify) {
      Swal.fire({
        title: '¿Seguro quiere realizar estos cambios?',
        text: 'Los cambios realizados no podrán revertirse',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Modificar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          form._id = this._id;
          this._service.modify(form).then((response)=>{
            this.loadList();
            this.resetAll();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    } else {
      Swal.fire({
        title: '¿Seguro quiere crear un nuevo usuario?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Crear',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this._service.create(form).then((response)=>{
            this.loadList();
            this.resetAll();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
      })
    }
  }

  showElem(elem: any) {
    this._id = elem._id;
    this.modify = true;
    this.elemForm.patchValue(elem);
  }

  async loadList() {
    this.elements = await this._service.getList();
  }

  resetAll() {
    this._id = null;
    this.modify = false;
    this.elemForm.reset();
  }


}
