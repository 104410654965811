import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { FlightScheduleService } from '../../services/flight-schedule.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LocationService } from '../../services/location.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  elements: any;
  dtOptions: any = {};
  otherList: any;
  dtTrigger = new Subject();
  passenger = false;
  elemForm = new FormGroup({
    schedule: new FormControl()
  });
  gouvernmet = false;

  constructor(private readonly _service: FlightScheduleService) { }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: 'Bfrtip',
      buttons: [
        'excel'
      ]
    };
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async send () {
    const {schedule} = this.elemForm.value;
    this.elements = await this._service.getPassengersByCodeSchedule(schedule);
    const tem: any = await this._service.isGovernmentSchedule(schedule);
    if (tem.governmentContractorFlight) {
      this.gouvernmet = true;
    }
    if (this.elements.length <= 0) {
      Swal.fire({
        title: 'Sorry, we have not found passengers with the selected filters',
        icon: 'info'
      })
    }
    if (this.elements.length > 0) {
      this.passenger = true;
    }
    //this.rerender();
  }

  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.destroy();
  //     this.dtTrigger.next();
  //   });
  // }

  async checkin(element: any) {
    const {schedule} = this.elemForm.value;
    const { passport } = element;
    const splitPassport = passport.split('');
    splitPassport.reverse();
    let lastFourPassportTemp = splitPassport.slice(0, 4);
    let lastFourPassportReal = '';
    lastFourPassportTemp.reverse();
    for (var i of lastFourPassportTemp) {
      lastFourPassportReal += i;
    }
    const { value: lastFourPassport } = await Swal.fire({
      title: 'Please enter the last four digits of your passport',
      input: 'text',
      inputPlaceholder: 'Enter your last four digits of your passport'
    });
    if (lastFourPassport) {
      if (lastFourPassport == lastFourPassportReal) {
        this._service.checkin({schedule, passport}).then( async (response)=>{
          this.elements = await this._service.getPassengersByCodeSchedule(schedule);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        });
      } else {
        Swal.fire({
          title: 'The passport does not match',
          icon: 'info'
        });
      }
    }
  }

  resetAll() {
    this.elements = [];
    this.elemForm.reset();
    //this.rerender();
  }

}
