import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AirplaneService {

  url: string = 'airplane';

  constructor(private http: HttpClient) { }

  getList() {
    return this.http.get(`${environment.urlApi}/${this.url}`).toPromise();
  }

  getOne(id: string) {
    return this.http.get(`${environment.urlApi}/${this.url}/${id}`).toPromise();
  }

  create(body: any) {
    return this.http.post(`${environment.urlApi}/${this.url}`, body).toPromise();
  }

  modify(body: any) {
    return this.http.put(`${environment.urlApi}/${this.url}`, body).toPromise();
  }

}
